@use '@/styles/utils/mixins.scss' as *;

.educationalArticlesWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  margin: 6.25rem 0;
  @include tab() {
    gap: 3.75rem;
  }
  @include mob() {
    margin: 5rem 0;
    padding-top: 5rem;
    border-top: 1px solid $primaryAlt;
  }

  .mediaList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
